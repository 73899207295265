import React, { useEffect, useState } from "react";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import Login from "./Login";
import NavBarPersistent from "../private/NavBarPersistent";
import { LegalTransactionOverview } from "../private/LegalTransaction/LegalTransactionOverview";
import { useFetch } from "../../hooks/useFetch";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { IGender } from "../../Interfaces/IGender";
import { IAcademicTitle } from "../../Interfaces/IAcademicTitle";
import { ICountry } from "../../Interfaces/ICountry";
import { IMIMEType } from "../../Interfaces/IMIMEType";
import { actionCreatorsAcademicTitle, actionCreatorsCountry, actionCreatorsGender, actionCreatorsMIMType } from "../../redux/actionCreators";
import { Alert } from "@mui/material";
import { ClientEdit } from "../private/Client/ClientEdit";
import { IConfig } from "../../Interfaces/IConfig";
import { DownloadArea } from "../private/DonwloadArea/DownloadArea";

interface IProps {
  configObject: IConfig;
}


export const PrivatePageRouter:React.FC<IProps> = ({configObject}) => {
    const dispatch = useDispatch();
    // Contact
    const { setGenderArray } = bindActionCreators(actionCreatorsGender, dispatch);
    const { setAcademicTitleArray } = bindActionCreators(actionCreatorsAcademicTitle, dispatch);
    const { setCountryArray } = bindActionCreators(actionCreatorsCountry, dispatch);
    // Dokumente
    const { setMIMETypeArray } = bindActionCreators(actionCreatorsMIMType, dispatch);

    // Laden aus dem Backend
    // Contact
    const [localGenderArray, setLocalGenderArray, wasLocalSuccessfullyGenderArray] = useFetch<IGender[]>("/gender");
    const [localAcademicTitleArray, setLocalAcademicTitleArray, wasLocalSuccessfullyAcademicTitleArray] = useFetch<IAcademicTitle[]>("/academictitle");
    const [localCountryArray, setlocalCountryArray, wasLocalSuccessfullyCountryArray] = useFetch<ICountry[]>("/country");
    // Dokumente
    const [localMIMETypeArray, setlocalMIMETypeArray, wasLocalSuccessfullyMIMETypeArray] = useFetch<IMIMEType[]>("/mimetype");


    // Speichern in Redux
    // Contact
    useEffect(() => {(localGenderArray) && setGenderArray(localGenderArray)},[localGenderArray]);
    useEffect(() => {(localAcademicTitleArray) && setAcademicTitleArray(localAcademicTitleArray)},[localAcademicTitleArray]);
    useEffect(() => {(localCountryArray) && setlocalCountryArray(localCountryArray)},[localCountryArray]);
    // Dokumente
    useEffect(() => {(localMIMETypeArray) && setMIMETypeArray(localMIMETypeArray)},[localMIMETypeArray]);


    if (
        !wasLocalSuccessfullyGenderArray
        || !wasLocalSuccessfullyAcademicTitleArray
        || !wasLocalSuccessfullyCountryArray
        // Documente
        || ! wasLocalSuccessfullyMIMETypeArray
    ) { return <Alert severity="error">Es ist ein fataler Fehler aufgertetten!</Alert> }
    else if (
        localGenderArray === undefined
        || localAcademicTitleArray === undefined
        || localCountryArray === undefined
        // Dokuemten
        || localMIMETypeArray === undefined
    ) { return <CustomCircularProgress/> }
    else {
        return(
            <>
              <Router>
                <NavBarPersistent configObject={configObject}>
                  <Routes>
                      <Route index element={<LegalTransactionOverview configObject={configObject} />} />
                      <Route path="/:idLegalTransaction" element={<LegalTransactionOverview configObject={configObject} />} />
                      <Route path="/:idLegalTransaction/:view" element={<LegalTransactionOverview configObject={configObject}/>} />

                      <Route path="/account" element={<ClientEdit/>} />
                      <Route path="/download_area" element={<DownloadArea/>}/>
                  </Routes>
                </NavBarPersistent>
              </Router>
            </>
        )
    }
}
