import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TextField,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { LegalPhaseRow } from './LegalPhaseRow';
import { ILegalPhaseFullObject } from '../../../../Interfaces/ILegalPhase';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { IConfig } from '../../../../Interfaces/IConfig';
import { LegalPhaseDocumentView } from './LegalPhaseDocumentView';

interface IProps {
  configObject: IConfig;
  legalTransaction: ILegalTransactionFullObject;
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
  setLegalPhaseFullObjectArray: Function;
}

export const LegalPhaseMain: React.FC<IProps> = props => {
  const [legalPhaseFullObjectArray, setLegalPhaseFullObjectArray] = useState<
    ILegalPhaseFullObject[]
  >([]);
  const [currentView, setCurrentView] = useState<
    'phase_view' | 'document_view' | string
  >('phase_view');

  useEffect(() => {
    /// map: LegalPhase aufbereiten, falls Postion nicht vorhanden
    setLegalPhaseFullObjectArray([
      ...legalPhaseFullObjectArray.map((currentLegalPhase, idx) =>
        currentLegalPhase.Position !== null
          ? currentLegalPhase
          : { ...currentLegalPhase, Position: idx + 1 }
      ),
    ]);
    /// end: map
  }, [legalPhaseFullObjectArray, props.legalPhaseFullObjectArray]);

  return (
    <>
      <Typography variant='h5'>Aktueller Statusverlauf</Typography>

      <Box sx={{ float: 'right', mb: 3 }}>
        Fragen zum Statusverlauf? Halten Sie Ihre Maus oder Finger über den
        Fortschrittsbalken für mehr Informationen oder schreiben Sie uns eine{' '}
        <a
          href={`mailto:${props.configObject.email}?subject=[${
            props.legalTransaction.TransactionNumberArray === null ||
            props.legalTransaction.TransactionNumberArray.length === 0
              ? props.legalTransaction.Title
              : props.legalTransaction.TransactionNumberArray[0]
          }]: Statusverlauf`}
        >
          E-Mail
        </a>
        !
      </Box>
      <Box sx={{ mt: 2, mb: 3 }}>
        <TextField
          label='Ansicht'
          value={currentView}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCurrentView(e.target.value!)
          }
          size='small'
          select
        >
          <MenuItem key='phase-view-normal' value='phase_view'>
            Vollständiger Statusverlauf
          </MenuItem>
          <MenuItem key='phase-view-document' value='document_view'>
            Dokumentenansicht
          </MenuItem>
        </TextField>
      </Box>

      {currentView === 'phase_view' ? (
        <Grid container spacing={1}>
          {props.legalPhaseFullObjectArray
            .sort((a, b) => (Number(a.Position) < Number(b.Position) ? -1 : 1))
            .map(x => (
              <LegalPhaseRow
                key={`idLegalPhase-${x.idLegalPhase}`}
                configObject={props.configObject}
                legalTransaction={props.legalTransaction}
                legalPhaseFullObject={x}
                legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
                setLegalPhaseFullObjectArray={setLegalPhaseFullObjectArray}
              />
            ))}
        </Grid>
      ) : (
        <LegalPhaseDocumentView
          configObject={props.configObject}
          legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
          legalTransaction={props.legalTransaction}
          setLegalPhaseFullObjectArray={props.setLegalPhaseFullObjectArray}
        />
      )}
    </>
  );
};
