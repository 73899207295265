import React, { useEffect, useState } from "react";
import { ILegalTransactionFullObject } from "../../../Interfaces/ILegalTransaction";
import { LegalPhaseMain } from "./LegalPhase/LegalPhaseMain";
import { Alert, Box, Button, Card, CardContent, Grid, Step, StepLabel, Stepper, Tab, TextField, Typography, useMediaQuery } from "@mui/material";
import { DocumentOverview } from "../Document/DocumentOverview";
import { ILegalTransactionProgress } from "../../../Interfaces/ILegalTransactionProgress";
import { ILegalRole } from "../../../Interfaces/ILegalRole";
import { LegalTransactionInvitePerson } from "./LegalTransactionInvitePerson";
import { LegalTransactionDraftOverview } from "./LegalTransactionDraftOverview";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { IConfig } from "../../../Interfaces/IConfig";


interface  IProps {
    configObject: IConfig;
    legalTransactionObject: ILegalTransactionFullObject;
    legalProgressArray: ILegalTransactionProgress[];
    view?: string;
    handleClose: Function;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }




export const LegalTransactionView:React.FC<IProps> = (props) => {
    const isDesktop = useMediaQuery('(min-width:600px)');

    
    const [currentPermission, setCurrentPermission] = useState(
        (props.legalTransactionObject.CurrentPermission !== null) 
        ? props.legalTransactionObject.CurrentPermission
        : {
            idLegalRole: -1,
            idLegalTransaction: -1,
            LegalRole: "Keine Rolle definier",
            viewDataCollection: true,
            viewDraft: true,
            viewDate: true,
            viewTransaction: true,
            viewInvoice: true,
            viewCertificate: true,
            canInvite: false,
            canUpload: true,
        } as ILegalRole
    );

    /*
        ERWEITERUNG FÜR Draft und DataCollection notwendig!!!
    */
    const [currenTab, setCurrenTab] = useState(
        (props.view !== undefined) ? props.view
        : (!currentPermission.viewTransaction && !currentPermission.viewInvoice && !currentPermission.viewCertificate && !currentPermission.viewDraft) ? "nopermission"
        : (currentPermission.viewTransaction) ? "transaction"
        : (currentPermission.viewCertificate) ? "certification"
        : (currentPermission.viewInvoice) ? "invoice"
        : "draft"
    );

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrenTab(newValue);
    };

    return(
        <>
            <Typography variant="h5" sx={{mb: 3}}>
                {props.legalTransactionObject.Title}
            </Typography>

            <Box sx={{mt: 2}}/>
            <Typography variant="h6">Grundinformationen</Typography>
            <Card variant="outlined">
                <CardContent>
                    <Grid container spacing={2} sx={{mb: 5}}>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Art"
                                value={props.legalTransactionObject.LegalTransactionType}
                                size="small"
                                variant="filled"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                label="Bezeichnung"
                                value={props.legalTransactionObject.Title}
                                size="small"
                                variant="filled"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={3}>
                            { (props.legalTransactionObject.TransactionNumberArray.length === 0)
                                ? <TextField label="Vorgangsnummer" size="small" variant="filled" fullWidth disabled/>
                                : props.legalTransactionObject.TransactionNumberArray.map(x => 
                                    <TextField
                                        key={x.TransactionNumberEntry}
                                        label="Vorgangsnummer"
                                        value={x.TransactionNumberEntry}
                                        size="small"
                                        variant="filled"
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                )}
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            { (props.legalTransactionObject.RecordNumberArray.length === 0)
                                ? <TextField label="Urkundennummer" size="small" variant="filled" fullWidth disabled/>
                                : props.legalTransactionObject.RecordNumberArray.map(x => 
                                    <TextField
                                        key={x.RecordNumberEntry}
                                        label="Urkundennummer"
                                        value={x.RecordNumberEntry}
                                        size="small"
                                        variant="filled"
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Status"
                                value={props.legalTransactionObject.LegalTransactionState}
                                size="small"
                                variant="filled"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    {(currentPermission.idLegalRole > 0) &&

                        <Grid container spacing={2} sx={{mb: 5}}>
                            <Grid item xs={6} sm={8}>
                                <TextField
                                    label="Ihre Rolle"
                                    value={currentPermission.LegalRole}
                                    size="small"
                                    variant="filled"
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                {(currentPermission.canInvite) &&
                                    <LegalTransactionInvitePerson
                                        currentPermission={currentPermission}
                                        legalTransactionObject={props.legalTransactionObject}
                                    />
                                }
                            </Grid>
                        </Grid>
                    }

                    <Box sx={{ mt: 5, height: (isDesktop) ? 30 : undefined , mb: 5 }}>
                        <Stepper 
                            activeStep={props.legalTransactionObject.idLegalTransactionProgress} 
                            orientation={(isDesktop) ? "horizontal" : "vertical"}
                            alternativeLabel={isDesktop}
                        >
                            {props.legalProgressArray.map(x => 
                                <Step 
                                    key={x.idLegalTransactionProgress} 
                                    completed={Number(props.legalTransactionObject.idLegalTransactionProgress) > x.idLegalTransactionProgress}
                                    active={Number(props.legalTransactionObject.idLegalTransactionProgress) === x.idLegalTransactionProgress}
                                >
                                    <StepLabel>{x.LegalTransactionProgress}</StepLabel>
                                </Step>
                            )}
                        </Stepper>
                    </Box>
                </CardContent>
            </Card>






            <Box sx={{mt: 10}}/>
            <Typography variant="h6">Ergänzende Informationen</Typography>
            <Card variant="outlined">
                <CardContent>
                    <TabContext value={currenTab}>
                        <Card variant="elevation">
                            <CardContent>
                            <TabList onChange={handleChange} aria-label="basic tabs example" variant="scrollable" indicatorColor="primary">
                                {(currentPermission.viewTransaction) && <Tab label="Statusverlauf" value="transaction"/>}
                                {(currentPermission.viewDraft) && <Tab label="Entwürfe" value="draft"/>}
                                {(currentPermission.viewCertificate) && <Tab label="Urkunden" value="certification" />}
                                {(currentPermission.viewInvoice) && <Tab label="Rechnungen" value="invoice" />}
                            </TabList>
                            </CardContent>
                        </Card>

                        <TabPanel value="nopermission">
                            <Alert severity="warning">
                                Sie haben keine lesende Berechtigungen
                            </Alert>
                        </TabPanel>


                        <TabPanel value="transaction">
                            { (props.legalTransactionObject.LegalPhaseArray.length === 0) 
                                ? <i>Derzeit steht kein Statusverlauf zur Verfügung.</i>
                                : <LegalPhaseMain
                                    configObject={props.configObject}
                                    legalTransaction={props.legalTransactionObject}
                                    legalPhaseFullObjectArray={props.legalTransactionObject.LegalPhaseArray}
                                    setLegalPhaseFullObjectArray={() => {}}
                                ></LegalPhaseMain>
                                
                            }
                        </TabPanel>

                        <TabPanel value="draft">
                            <LegalTransactionDraftOverview
                                configObject={props.configObject}
                                legalTransactionObject={props.legalTransactionObject}
                                draftArray={props.legalTransactionObject.DraftArray}
                                setDraftArray={() => {}}
                                draftMessageArray={props.legalTransactionObject.DraftMessageArray}
                                setDraftMessageArray={() => {}}
                            />
                        </TabPanel>

                        <TabPanel value="certification">
                            <DocumentOverview
                                key={`DocumentOverview-CertificateArray`}
                                configObject={props.configObject}
                                legalTransactionObject={props.legalTransactionObject}
                                idLegalRequirement={props.legalTransactionObject.idLegalTransaction}
                                documentArray={props.legalTransactionObject.CertificateArray}
                                setDocumentArray={() => {}}
                                allowRemove={false}
                                allowUpload={false}
                                title="Urkunden"
                                showReadPermission={false}
                                handleClose={props.handleClose}
                            />
                        </TabPanel>

                        <TabPanel value="invoice">
                            <DocumentOverview
                                key={`DocumentOverview-InvoiceArray`}
                                configObject={props.configObject}
                                legalTransactionObject={props.legalTransactionObject}
                                idLegalRequirement={props.legalTransactionObject.idLegalTransaction}
                                documentArray={props.legalTransactionObject.InvoiceArray}
                                setDocumentArray={() => {}}
                                allowRemove={false}
                                allowUpload={false}
                                title="Rechnungen"
                                showReadPermission={false}
                                handleClose={props.handleClose}
                            />
                        </TabPanel>

                    </TabContext>
                </CardContent>
            </Card>
        </>
    )
}