import { combineReducers } from "@reduxjs/toolkit";
import slice_Gender from "./slices/gender";
import slice_AcademicTitle from "./slices/academicTitle";
import slice_Country from "./slices/country";
// Dokumente
import slice_MIMEType from "./slices/mimeType"

const mainReducer = combineReducers({
    // Contact
    genderArray: slice_Gender,
    academicTitleArray: slice_AcademicTitle,
    countryArray: slice_Country,
    // Dokuemten
    mimeTypeArray: slice_MIMEType
})
export default mainReducer;
export type State = ReturnType<typeof mainReducer>;