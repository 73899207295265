import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { IConfig } from "../../Interfaces/IConfig";
import md5 from 'md5';
import { uploadFetch } from "../../hooks/useFetch";
import { getIsEmail } from "../core/generic_helper";
import { useNavigate, useParams } from "react-router-dom";



interface IProps {
    msg: string;
    setMsg: Function;
    setIdContact: Function;
    setTmpSessionToken: Function;
    setCurrentPage: Function;
    sendFunction: Function;
    configObject: IConfig;
    enableAutoLogin: boolean;
}


const getFromParams = (params:any,attr:"email"|"pw") => {
    if (params.email != undefined && params.emailProvider != undefined && params.pw != undefined) {
        if (attr === "email") {
            return `${params.email}@${params.emailProvider}`
        } else {
            return params.pw
        }
    }
    return ""
}


export const LoginMain:React.FC<IProps> = ({
    msg, setMsg, 
    setIdContact, setTmpSessionToken, setCurrentPage,
    sendFunction,
    configObject,
    enableAutoLogin
}) => {
    const params = useParams();
    const navigation = useNavigate();
    const loginBtnRef = useRef<HTMLButtonElement|null>(null);

    const [email, setEmail] = useState<string>(enableAutoLogin ? getFromParams(params,"email") : "");
    const [pw, setPw] = useState<string>(enableAutoLogin ? getFromParams(params,"pw"): "");

    const [isOpenRequestResetPasswort, setIsOpenRequestResetPasswort] = useState(false);
    const [emailToRequest, setEmailToRequest] = useState("");
    const [wasSend, setWasSend] = useState(false);

    const handleOpenRequestNewPw = () => {
        setEmailToRequest("");
        setIsOpenRequestResetPasswort(true);
    }

    const handleAfterSave = (res:any) => {
        setTmpSessionToken(res["TempSessionToken"]);
        setIdContact(res["idContact"]);
        setCurrentPage(1)
    }


    const handleSubmitLogin = (e: React.SyntheticEvent) => {
        e.preventDefault()
        sendFunction(
            {"Email": email, "Password": md5(pw)} as any,
            handleAfterSave,
            () => {setEmail("");setPw("")}
        )
    }



    const handleSendRequestNewPw = () => {
        if (emailToRequest !== "" && getIsEmail(emailToRequest)) {
            uploadFetch("/contact/requestNewPassword", true, {"Email": emailToRequest}, () => {
            });
            setWasSend(true);
        }
    }

    useEffect(() => {
        if (enableAutoLogin && loginBtnRef !== null && params.email != undefined && params.emailProvider != undefined && params.pw != undefined) {
            navigation("/");
            loginBtnRef.current?.click();
        }
    },[loginBtnRef,params])



    return(
        <form onSubmit={handleSubmitLogin} autoComplete="on">
            <Dialog
                open={isOpenRequestResetPasswort}
                onClose={() => setIsOpenRequestResetPasswort(false)}
                maxWidth="sm" fullWidth
            >
                {(wasSend) ?
                    <>
                        <DialogTitle>Passwort zurücksetzen</DialogTitle>
                        <DialogContent>
                            <Alert severity="success">
                                Wenn Sie ein Konto in unserem Mandantenportal haben, erhalten Sie eine <span style={{whiteSpace: "nowrap"}}>E-Mail</span> mit einem Link, über den Sie das Passwort zurücksetzen können.
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={() => {
                                setIsOpenRequestResetPasswort(false);
                                setWasSend(false)
                            }}>Schließen</Button>
                        </DialogActions>
                    </>
                    :
                    <>
                        <DialogTitle>Passwort zurücksetzen</DialogTitle>
                        <DialogContent>

                            Bitte geben Sie Ihre E-Mail-Adresse an.
                            <Box sx={{mt: 2}}/>
                            <TextField
                                label="E-Mail"
                                value={emailToRequest}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailToRequest(event.target.value)}
                                error={emailToRequest !== "" && !getIsEmail(emailToRequest)}
                                fullWidth
                                size="small"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined"
                                    onClick={() => setIsOpenRequestResetPasswort(false)}>Schließen</Button>
                            <Button variant="contained"
                                    disabled={emailToRequest === "" || !getIsEmail(emailToRequest)}
                                    onClick={handleSendRequestNewPw}>Senden</Button>
                        </DialogActions>
                    </>
                }

            </Dialog>

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '100vh'}}
            >
                <img width={250} src={`data:${configObject.logo_mime};base64,${configObject.logo}`}/>
                <Box sx={{mt: 10}}/>
                <Grid item xs={3} style={{minWidth: 300}}>
                    <TextField
                        label="E-Mail"
                        value={email}
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(String(event.target.value))}
                        required
                    />
                </Grid>

                <Box sx={{mt: 2}}/>
                <Grid item xs={3} style={{minWidth: 300}}>
                    <TextField
                        type={'password'}
                        label="Passwort"
                        fullWidth
                        value={pw}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPw(String(event.target.value))}
                        required
                    />

                </Grid>

                <Box sx={{mt: 3}}/>
                <Grid item xs={3} style={{minWidth: 300}}>
                    <div style={{float: 'right'}}>
                        <Button size="small" variant="contained" color="primary" type="submit" ref={loginBtnRef}>Anmelden</Button>
                    </div>
                </Grid>

                <Grid item xs={3} sx={{mt: 2}} style={{minWidth: 300}}>
                    <div style={{float: 'right'}}>
                        <Button onClick={handleOpenRequestNewPw} size="small">Passwort zurücksetzen</Button>
                    </div>
                </Grid>

                <Box sx={{mt: 3}}/>
                <Grid item xs={3} style={{minWidth: 300}}>
                    {(msg !== "") &&
                        <Alert severity="error">
                            {msg}
                        </Alert>
                    }
                </Grid>
            </Grid>
        </form>
    )
}