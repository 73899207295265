import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMIMEType } from "../../Interfaces/IMIMEType";


const initialState =  { storeAcademicTitle: [] as IMIMEType[] }; 

const sliceMIMEType = createSlice({
    name: "MIMETYPEARRAY",
    initialState,
    reducers: {
        setMIMETypeArray: (state, action: PayloadAction<IMIMEType[]>) => {
            state.storeAcademicTitle = [...action.payload];
        },
        addUpdateMIMETypeArray: (state, action: PayloadAction<IMIMEType>) => {
            let testObject = state.storeAcademicTitle.find(x => x.idMIMEType === action.payload.idMIMEType);
            
            if (testObject) {
                state.storeAcademicTitle = [...state.storeAcademicTitle.map(x => x.idMIMEType === action.payload.idMIMEType ? action.payload : x)];
            } else {
                state.storeAcademicTitle = [action.payload, ...state.storeAcademicTitle];
            }
        },
    }
})
export const { setMIMETypeArray, addUpdateMIMETypeArray } = sliceMIMEType.actions;
export default sliceMIMEType.reducer;