import React, { useEffect, useState } from "react";
import { Box, Checkbox, Collapse, FormControlLabel, Grid, TextField } from "@mui/material";
import ReactPasswordChecklist from "react-password-checklist";
import md5 from "md5";
import { IContact } from "../../../Interfaces/IContact";

interface IProps {
    editClient: IContact;
    setUserObject: Function;
    setIsValid?: Function;
    disabled: boolean;
}


export const ClientPassword:React.FC<IProps> = (props) => {
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        (props.setIsValid !== undefined) && props.setIsValid(isValid);
    },[isValid])

    useEffect( () => {
        if (password === "") {
            props.setUserObject({...props.editClient, Password: undefined})
        } else {
            props.setUserObject({...props.editClient, Password: md5(password)})
        }
    },[password])


    return(
        <>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Collapse in={true}>
                        <TextField
                            type="password"
                            label="Passwort"
                            size="small"
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setPassword( event.target.value )}}
                            fullWidth
                            disabled={props.disabled}
                        />
                    </Collapse>
                </Grid>

                
                <Grid item sm={12}>
                    <Collapse in={password !== ""}>
                        <TextField
                            type="password"
                            label="Passwort wiederholen"
                            size="small"
                            value={passwordAgain}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPasswordAgain( event.target.value )}
                            fullWidth
                            disabled={props.disabled}
                        />
                    </Collapse>
                </Grid>

                <Grid item sm={12}>
                    <Collapse in={password !== ""}>
                        <ReactPasswordChecklist
                            rules={["minLength","specialChar","number","capital","match"]}
                            minLength={12}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={(isValid) => setIsValid(isValid)}
                            messages={{
                                minLength: "Das Passwort hat 12 Zeichen.",
                                specialChar: "Das Passwort hat ein Spezialzeichen.",
                                number: "Das Passwort hat eine Zahl.",
                                capital: "Das Passwort hat ein Großbuchstaben.",
                                match: "Die Passwörter stimmen überein",
                            }}
                        />
                    </Collapse>
                </Grid>
                
            </Grid>
        </>
    )
}