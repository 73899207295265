import { Button, Dialog, DialogActions, DialogContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ILegalRequirement } from "../../../../Interfaces/ILegalRequirement";
import { IConfig } from "../../../../Interfaces/IConfig";
import { ILegalTransactionFullObject } from "../../../../Interfaces/ILegalTransaction";
import { ILegalPhaseFullObject } from "../../../../Interfaces/ILegalPhase";
import { Download, UploadFile } from "@mui/icons-material";
import { DocumentOverview } from "../../Document/DocumentOverview";
import { IDocument } from "../../../../Interfaces/IDocument";


interface IProps {
    configObject: IConfig;
    legalTransaction: ILegalTransactionFullObject;
    legalPhaseFullObjectArray: ILegalPhaseFullObject[];
    setLegalPhaseFullObjectArray: Function;
}


export const LegalPhaseDocumentView:React.FC<IProps> = (props) => {
    const [currentRequirements,setCurrentRequirements] = useState<ILegalRequirement[]>([]);
    const [currentReqDoc,setCurrentReqDoc] = useState<ILegalRequirement|null>(null);
    //
    const [isOpenClient, setIsOpenClient] = useState(false);
    const [isOpenEmployees, setIsOpenEmployees] = useState(false);
    const [isOpenUpload, setIsOpenUpload] = useState(false);
    //
    const [documentArrayClient, setDocumentArrayClient] = useState<IDocument[]>([]);
    const [documentArrayEmployees, setDocumentArrayEmployees] = useState<IDocument[]>([]);
    //


    const handleOpenEmployees = (localReq:ILegalRequirement) => {
        setCurrentReqDoc(localReq)
        setDocumentArrayEmployees((localReq.DocumentArrayEmployees === undefined) ? [] : localReq.DocumentArrayEmployees);
        setIsOpenEmployees(true);
    }
    const handleOpenClient = (localReq:ILegalRequirement) => {
        setCurrentReqDoc(localReq)
        setDocumentArrayClient((localReq.DocumentArrayClient === undefined) ? [] : localReq.DocumentArrayClient);
        setIsOpenClient(true);
    }



    const handleCloseClient = () => {
        let foundItem = documentArrayClient.find(x => x.idDocument < 0);

        if (foundItem === undefined) {
            setIsOpenClient(false);
        } else {
            setIsOpenClient(false);
            setIsOpenUpload(true);
        }
    }

    useEffect(() => {
        let tmpArray:ILegalRequirement[] = [];
        
        props.legalPhaseFullObjectArray
            .map(currentPhase => currentPhase.LegalRequirementArray.map(currentReq =>{
                if (Boolean(currentReq.canUploadFilesClient) || Boolean(currentReq.canUploadFilesEmployees)) {
                    tmpArray.push(currentReq)
                }
            }))
        
            setCurrentRequirements(tmpArray)
        
    },[props.legalPhaseFullObjectArray])


    return(
        <>
            <Dialog
                open={currentReqDoc !== null}
                onClose={() => setCurrentReqDoc(null)}
                maxWidth="lg" fullWidth
            >
                <DialogContent>
                    <DocumentOverview
                        key={`DocumentOverview-client-${currentReqDoc?.idLegalRequirement || -1}`}
                        configObject={props.configObject}
                        legalTransactionObject={props.legalTransaction}
                        idLegalRequirement={currentReqDoc?.idLegalRequirement || -1}
                        documentArray={documentArrayClient}
                        setDocumentArray={setDocumentArrayClient}
                        allowRemove={false}
                        allowUpload={true}
                        title="Ihre Daten"
                        showReadPermission={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setCurrentReqDoc(null)}>Okay</Button>
                </DialogActions>
            </Dialog>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Maßnahme</TableCell>
                        <TableCell>Bereitgestelle Dokumente</TableCell>
                        <TableCell>Hochgeladene Dokumente</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(currentRequirements.length === 0) &&
                        <TableRow>
                            <TableCell colSpan={3}><i>Derzeitig sind keine Einträge verfügbar.</i></TableCell>
                        </TableRow>
                    }
                    {currentRequirements.map(x =>
                        <TableRow key={`currentReq-documentview-${x.idLegalRequirement}`}>
                            <TableCell>{x.LegalRequirement}</TableCell>
                            <TableCell>

                                {x.DocumentArrayEmployees?.map(x => x.FileName).join(",\n")}
                                {(x.DocumentArrayEmployees !== undefined && x.DocumentArrayEmployees?.length > 0 && x.canUploadFilesEmployees == true) && 
                                    <IconButton
                                        title="Runterladen von bereitgestellten Dateien"
                                        size="small" 
                                        onClick={() => handleOpenEmployees(x)}
                                    ><Download/></IconButton>
                                }
                            </TableCell>
                            <TableCell>
                                {x.DocumentArrayClient?.map(x => x.FileName).join(", ")}
                                {(x.canUploadFilesClient == true) && 
                                    <IconButton 
                                        title="Hochladen und runterladen von eigenen Dateien"
                                        size="small" 
                                        onClick={() => handleOpenClient(x)}
                                    ><UploadFile/></IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    )
}