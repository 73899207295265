export const getIsEmail = (currentEmail:string|null|undefined) => {
    if (currentEmail === undefined || currentEmail === null) {
        return false
    } else {
        let splitetByAd = currentEmail.split("@");

        if (splitetByAd.length !== 2) {
            return false;
        }

        let splitetByPoint = splitetByAd[1].split(".");
        
        return (splitetByPoint.length === 2 && splitetByPoint[1].length > 0)
    }
}

export const checkIsUnderTen = (currentValue:number)  => {
    if (currentValue < 10) {
        return `0${currentValue}`
    } else {
        return `${currentValue}`
    }
}


export const getStringdateFromDate = (dateObject:Date) => {
    let y = dateObject.getFullYear();
    let m = dateObject.getMonth() +1;
    let d = dateObject.getDate();
    return `${y}-${checkIsUnderTen(m)}-${checkIsUnderTen(d)}`
}

export const castWithZeroTimezone = (targetValue:string|null) => {
    if (targetValue === null) {
        return null
    } else {
        if (targetValue.includes("Z")) {
            return new Date(targetValue);
        } else {
            return new Date(targetValue.replace(" ","T") + "Z");
        }
        //console.log(targetValue.replace(" ","T") + "Z")
        
        //return new Date(`${dateSting}T${targetValue}Z`)
    }    
}

export const getZeroTimezoneStringFromDate = (dataObject:Date) => {
    if (isNaN(dataObject.getTime())) {
        return null;
    }

    //let hours = dataObject.getUTCHours();
    //let minutes = dataObject.getMinutes();

    let isoString = dataObject.toISOString();

    let returnString = isoString.replace("T", " ").split(".")[0]

    return `${returnString}`

    /*
    let splittedArray = dataObject.toISOString().split("T")

    if (splittedArray.length !== 2) {
        console.warn(`Can't cast Date ${dataObject} to string`);
        return null;
    } else {
        let minutes = dataObject.getUTCHours();
        return splittedArray[1].split(".")[0]
    }
    */
}


export const getHfromMinutes = (diffMin:number) => {
    let minutes = diffMin %60;
    let hours = (diffMin-minutes)/60;

    return `${checkIsUnderTen(hours)}:${checkIsUnderTen(minutes)} Std.`
}