import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAcademicTitle } from "../../Interfaces/IAcademicTitle";


const initialState =  { storeAcademicTitle: [] as IAcademicTitle[] }; 

const sliceAcademicTitle = createSlice({
    name: "ACADEMICTITLEARRAY",
    initialState,
    reducers: {
        setAcademicTitleArray: (state, action: PayloadAction<IAcademicTitle[]>) => {
            state.storeAcademicTitle = [...action.payload];
        },
        addUpdateAcademicTitlerArray: (state, action: PayloadAction<IAcademicTitle>) => {
            let testObject = state.storeAcademicTitle.find(x => x.idAcademicTitle === action.payload.idAcademicTitle);
            
            if (testObject) {
                state.storeAcademicTitle = [...state.storeAcademicTitle.map(x => x.idAcademicTitle === action.payload.idAcademicTitle ? action.payload : x)];
            } else {
                state.storeAcademicTitle = [action.payload, ...state.storeAcademicTitle];
            }
        },
    }
})
export const { setAcademicTitleArray, addUpdateAcademicTitlerArray } = sliceAcademicTitle.actions;
export default sliceAcademicTitle.reducer;