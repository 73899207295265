import React from "react";
import { ProvidedDataOverview } from "../Document/ProvidedDataOverview";


export const DownloadArea:React.FC = () => {
    return(
        <>
            <ProvidedDataOverview/>
        </>
    )
}