import React, { useEffect, useState } from "react";
import { getFetch, useFetch } from "../../../hooks/useFetch";
import { IDocument } from "../../../Interfaces/IDocument";
import { Alert, Box, Button, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { Download } from "@mui/icons-material";



export const ProvidedDataOverview:React.FC = () => {
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    const isDesktop = useMediaQuery('(min-width:600px)');
    //const [documentArray,setDocumentArray] = useState<IDocument[] | undefined>([]);
    //const [checkIsEnable,setCheckIsEnable] = useState(false);

    const [documentArray, setDocumentArray, wasSuccessfullyDocument] = useFetch<IDocument[]>("/document");

    const handleCheck = () => {
        sleep(20000).then(() => {
            getFetch("document",undefined,setDocumentArray);
        })
    }

    /*
    useEffect(() => {
        if (documentArray !== undefined) {   
            if (documentArray.length === 0) { 
                setDocumentArray(undefined)
                handleCheck();

            } else {
                sessionStorage.setItem("requested_data","false")
            }
        }
    },[documentArray])
    */

    const base64Download = (localDocumentObject:IDocument) => {
        if (localDocumentObject.Data !== undefined) {
            let byteCharacters = atob(localDocumentObject.Data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: "application/pdf"});
            // Erstelle Link zum BLOB
            let blobUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = blobUrl;
            a.download = localDocumentObject.FileName;
            a.click();
        }
    }

    const currentBody = () => {
        if (documentArray === undefined) {
            return(
                <>
                    <CircularProgress/> Die angefragten Dokumente werden geladen.
                </>
            )
        }
        else if (documentArray === undefined || documentArray.length === 0) {
            return <Alert severity="info">
                Derzeit sind keine Dokumente verfügbar. 
                <br/>Sofern Sie Dokumente angefragt haben, werden diese hier aufgelistet und können heruntergeladen werden. Bitte haben Sie einen Moment Geduld, während die Bereitstellung der Dokumente geprüft wird. Dieser Vorgang kann bis zu fünf Minuten dauern.
            </Alert>
        }
        else {
            return (
                <>
                    <Alert severity="info" sx={{mt: 2}}>
                        Um Mitternacht werden alle angefragten Dokumente von diesem Server gelöscht.
                    </Alert>
                    
                    <Table size="small" sx={{mt: 2}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Dateiname</TableCell>
                                <TableCell sx={{width: 20}}>Aktionen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documentArray.map(x =>
                                <TableRow sx={{cursor: "pointer"}} onClick={() => base64Download(x)}>
                                    <TableCell>{x.FileName}</TableCell>
                                    <TableCell>
                                        {(isDesktop)
                                            ? <Button variant="contained">Download</Button>
                                            : <IconButton><Download/></IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </>
            )
        }

    }

    return(
        <Box sx={{mt: 2, mb: 10}}>
            <Typography variant="h4">
                Meine Dokumente
            </Typography>
            {currentBody()}
        </Box>
    )
} 