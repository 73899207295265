import React, {useEffect, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Download, FileDownload, Upload, UploadFile } from '@mui/icons-material';
import { IConfig } from '../../../Interfaces/IConfig';


interface IProps {
    configObject: IConfig;
    isOpenParent?: boolean;
    setIsOpenParent?: Function;
    openOnPage?: string;
    disableButton?: boolean;
}

  



export const HelpOverview:React.FC<IProps> = ({configObject, isOpenParent, setIsOpenParent, openOnPage, disableButton }) => {
  const [expanded, setExpanded] = useState<string | false>((openOnPage === undefined) ? false : openOnPage);
  const [isOpenDialog,setIsOpenDialog] = useState(false);

  useEffect(() => {
    if (isOpenParent !== undefined) {
        setIsOpenDialog(isOpenParent);
    }
  },[isOpenParent])



    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handleClose = () => {
        setIsOpenDialog(false);
        (setIsOpenParent !== undefined) && setIsOpenParent(false);
    }

  return (
    <>
        { (disableButton === undefined || disableButton === false) &&
            <Button onClick={() => setIsOpenDialog(true)} variant='outlined'>Hilfe</Button>
        }
        

        <Dialog open={(isOpenParent === undefined) ? isOpenDialog : isOpenParent} onClose={handleClose}>
            <DialogContent>Hilfe</DialogContent>
            <DialogContent>
                <Accordion expanded={expanded === 'get_documents'} onChange={handleChange('get_documents')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="get_documents"
                        id="get_documents-header"
                    >
                        <Typography>
                            Wo finde ich meine Dokumente?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            Ihre Dokumente wie bspw. Urkunden und Rechnungen finden Sie im Vorgang unter Urkunden / Rechnungen.
                        </Typography>
                        <Typography>
                            Klicken Sie bei einem Vorgang auf "Öffnen". Unter "Ergänzende Informationen" finden Sie ein Menü mit den Unterpunkten Entwurf, Urkunden, Rechnungen und ggf. Statusverlauf. Wenn Sie beispielsweise auf den Menüpunkt "Urkunden" klicken, werden Ihnen die verfügbaren Dokumente in einer Tabelle angezeigt.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'download_documents'} onChange={handleChange('download_documents')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="download_documents"
                        id="download_documents-header"
                    >
                        <Typography>
                            Wie erhalte ich meine angefragten Dokumente?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            Aus Sicherheitsgründen werden in diesem Mandantenportal keine Dokumente gespeichert. Klicken Sie auf <i>Anfragen</i> bzw. auf <Download/> . Das gewünschte Dokument wird Ihnen dann vom Server des Notarbüros bereitgestellt. Sie erhalten dafür eine separate E-Mail mit dem Download-Link zum Dokument.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'get_draft'} onChange={handleChange('get_draft')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="get_draft"
                        id="get_draft-header"
                    >
                        <Typography>
                            Wo finde ich meinen (Vertrags-)Entwurf?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            Öffnen Sie dafür den relevanten Vorgang. Anschließend klicken Sie bitte im Menü der "Ergänzende Informationen" auf den Punkt "Entwurf".
                            Nun werden Ihnen alle Entwürfe und Kommentare dieser angezeigt.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'write_draft'} onChange={handleChange('write_draft')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="write_draft"
                        id="write_draft-header"
                    >
                        <Typography>
                            Wie kann ich Anmerkungen am (Vertrags-)Entwurf einbringen?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            Öffnen Sie den relevanten Vorgang. 
                            Anschließend klicken Sie im Menü "Ergänzende Informationen" auf den Punkt "Entwurf". 
                            Nun werden Ihnen alle Entwürfe und Kommentare angezeigt. 
                            <br/>Im Bereich "Kommentar" können Sie über die Klickfläche "Kommentar hinzufügen" Ihre (Änderungs-)Wünsche, Anmerkungen, Bedenken etc. hinterlegen. 
                            Die Kommentare sind für alle Beteiligten im Vorgang einsehbar und können von diesen kommentiert werden.
                            <br/><br/><b>Tipp:</b> Sie können in neuen Kommentare ganze Dokumente (bsow. eine Word-Datei) hochladen und somit dieses Dokument allen Partei zur Verfügung stellen.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'waht_is_requirement'} onChange={handleChange('waht_is_requirement')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="waht_is_requirement"
                        id="waht_is_requirement-header"
                    >
                        <Typography>
                            Was hat es mit dem Statusverlauf aufsich?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            <u>Achtung: Nicht bei jeder Vorgangsart wird der Statusverlauf bereitgestellt.</u>
                            <br/><br/>
                            Jeder Vorgang besteht aus mehreren Vollzügen, die sich wiederum aus mehreren Maßnahmen zusammensetzen. Um Ihren Vorgang erfolgreich abschließen zu können, müssen in der Regel alle Maßnahmen und Vollzüge erfüllt sein (Status auf "erledigt"). Manche Maßnahmen erfordern Ihre Interaktion. Beispielsweise kann die Maßnahme "Stammkapitaleinzahlung ist vollständig bestätigt" erst erfüllt werden, wenn Sie uns eine Bestätigung der Einzahlung des Stammkapitals bereitstellen.
                            <br/>Wenn Sie bei einer Maßnahme das Symbol <UploadFile/> sehen, können Sie durch Klicken des Symbols ein Dokument bereitstellen. Im vorhergehenden Beispiel könnten Sie beispielsweise einen Kontoauszug hochladen, um die Einzahlung des Stammkapitals zu bestätigen. Dokumente können auch explizit einer Maßnahme zugeordnet sein. Sofern maßnahmenspezifische Dokumente bereitstehen, wird Ihnen das Symbol <FileDownload/> angezeigt. Wenn Sie das Symbol anklicken, können Sie die entsprechenden Dokumente anfordern.

                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'invitate'} onChange={handleChange('invitate')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="invitate"
                        id="invitate-header"
                    >
                        <Typography>
                            Wie kann ich weitere Parteien zum Vorgang einladen?
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                            <u>Achtung: Sie benötigen die entsprechende Berechtigung, um weitere Parteien zum Vorgang einzuladen.</u>
                            <br/><br/>
                            Öffnen Sie bitte den relevanten Vorgang. Wenn Sie über die erforderlichen Berechtigungen verfügen, finden Sie im Bereich "Grundinformationen" die Klickfläche "Person zum Vorgang einladen".
                            Durch Klicken dieser Schaltfläche können Sie eine Person durch Angabe ihrer E-Mail-Adresse zum Vorgang einladen.
                            Bitte beachten Sie, dass die E-Mail-Adresse korrekt eingegeben wird. Nach dem Absenden können Sie die Einladung nicht mehr rückgängig machen oder korrigieren.
                            <br/><br/>
                            Die hinterlegte E-Mail-Adresse erhält eine Einladung zum Vorgang, die 24 Stunden gültig ist.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Box sx={{mt: 5}} />

                <Accordion expanded={expanded === 'helping'} onChange={handleChange('helping')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="helping"
                        id="helping-header"
                    >
                        <Typography>
                            Meine Frage konnte nicht beantwortet werden.
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography>
                        Schreiben Sie uns gerne eine E-Mail an <a href={`mailto:${configObject.email}`}>{configObject.email}</a>.
                        <br/><br/>
                        Bitte geben Sie in der E-Mail die E-Mail-Adresse an, mit der Sie sich in DiNo anmelden, sowie gegebenenfalls die Vorgangsnummer des Vorgangs, wenn Sie Fragen zu einem konkreten Vorgang haben.
                        <br/>
                        Die Vorgangsnummer eines Vorgangs finden Sie, indem Sie den Vorgang öffnen. Im Bereich "Grundinformationen" befindet sich das Feld "Vorgangsnummer". Falls Ihnen mehrere Vorgangsnummern angezeigt werden, geben Sie bitte alle Vorgangsnummern in der E-Mail an.                     
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Okay</Button>
            </DialogActions>
        </Dialog>
    </>
  );
}