import React, { useState } from "react";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import { DocumentOverview } from "../../Document/DocumentOverview";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { DocumentRow } from "../../Document/DocumentRow";
import { LegalRequirementRequestFiles } from "../LegalPhase/LegalRequirementRequestFiles";
import { IDocument } from "../../../../Interfaces/IDocument";
import { ILegalPhaseTemplateFullObject } from "../../../../Interfaces/ILegalPhaseTemplate";
import { ILegalTransactionFullObject } from "../../../../Interfaces/ILegalTransaction";
import { Download } from "@mui/icons-material";
import { IConfig } from "../../../../Interfaces/IConfig";


interface IProps  {
    configObject: IConfig;
    legalTransactionObject: ILegalTransactionFullObject;
    draftArray: IDocument[];
    setDraftArray: Function;
}


export const DraftDocumentOverview:React.FC<IProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [idDocument,setIdDocument] = useState<number|undefined>(undefined);

    const handleDownloadReques = (localIdDocument:number) => {
        setIdDocument(localIdDocument);
        setIsOpen(true);
    }


    return(
        <>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth="lg" fullWidth
            >
                <LegalRequirementRequestFiles
                    configObject={props.configObject}
                    idDocument={idDocument}
                    legalTransaction={props.legalTransactionObject}
                    documentArray={props.draftArray.filter(x => x.idDocument > 0)}
                    setIsOpen={setIsOpen}
                />
            </Dialog>



            <Typography variant="h5">
                Entwürfe
            </Typography>

            {(props.draftArray.length === 0) &&
                <>--- Keine Entwürfe vorhanden ---</>
            }


            <Timeline>
                {props.draftArray.map(x => 
                    <>
                        <TimelineItem>
                            <TimelineOppositeContent  >
                                {new Date(String(x.Created_at)).toLocaleString()}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent color="text.secondary"  sx={{ mb: 3 }}>
                                {x.FileName}
                                <br/>
                                <IconButton size="small" onClick={() => handleDownloadReques(x.idDocument)}><Download/></IconButton>
                            </TimelineContent>
                        </TimelineItem>
                    </>
                )}
            </Timeline>
        </>
    )
}