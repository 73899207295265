import React, { useEffect, useState } from "react";
import { Alert, Grid, TableCell, TableRow, Typography } from "@mui/material";
import { LegalRequirementRow } from "./LegalRequirementRow";
import { truncate } from "fs";
import { ILegalPhaseFullObject } from "../../../../Interfaces/ILegalPhase";
import { ILegalTransactionFullObject } from "../../../../Interfaces/ILegalTransaction";
import { LegalInfoText } from "./LegalInfoText";
import { IConfig } from "../../../../Interfaces/IConfig";

interface IProps {
  configObject: IConfig;
  legalTransaction: ILegalTransactionFullObject;
  legalPhaseFullObject: ILegalPhaseFullObject;
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
  setLegalPhaseFullObjectArray: Function;
}

export const LegalPhaseRow: React.FC<IProps> = (props) => {
  const [legalPhaseObject, setLegalPhaseObject] = useState(
    props.legalPhaseFullObject
  );
  const [legalReqArray, setLegalReqArray] = useState(
    props.legalPhaseFullObject.LegalRequirementArray
  );
  //
  const [isNotDisabled, setIsNotDisabled] = useState(true);

  /*
    useEffect(() => {
        setLegalPhaseObject({
            ...legalPhaseObject,
            isComplete: legalReqArray.every(x => x.isComplete == true),
            LegalRequirementArray: legalReqArray
        })
    },[legalReqArray])

    useEffect(() => {
        props.setLegalPhaseFullObjectArray([
            ...props.legalPhaseFullObjectArray.map(x => x.idLegalPhase === legalPhaseObject.idLegalPhase ? legalPhaseObject : x)
        ])
    },[legalPhaseObject])

    useEffect(() => {
        setIsNotDisabled(
            props.legalPhaseFullObject.PreconditionArray.length === 0
            || props.legalPhaseFullObject.PreconditionArray.every(x => {
                let testObject = props.legalPhaseFullObjectArray.find(y => y.idLegalPhase === x.idLegalPhase);
                
                if (testObject === undefined) {
                    return false;
                } else {
                    return (testObject.isComplete == true)
                }
            })
        )
    },[props.legalPhaseFullObjectArray])
    */

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Alert
          variant={isNotDisabled ? "standard" : "outlined"}
          severity={legalPhaseObject.isComplete ? "success" : "info"}
          action={
            <>
              <LegalInfoText currentObject={legalPhaseObject} />
            </>
          }
        >
          {legalPhaseObject.LegalPhase}
          {legalPhaseObject.PreconditionArray.length !== 0 && (
            <>
              <br />
              <Typography variant="caption">Vorgänger:</Typography>{" "}
            </>
          )}
          {
            <Typography variant="caption">
              {legalPhaseObject.PreconditionArray.map((x) => x.LegalPhase).join(
                ", "
              )}
            </Typography>
          }
        </Alert>
      </Grid>

      {legalReqArray.map((x) => (
        <LegalRequirementRow
          key={`idLegalRequirement-${x.idLegalRequirement}`}
          configObject={props.configObject}
          legalTransaction={props.legalTransaction}
          legalRequirement={x}
          legalRequirementArray={legalReqArray}
          setLegalRequirementArray={setLegalReqArray}
          isNotDisabled={isNotDisabled}
          startDeadlineDate={
            props.legalTransaction.StartEditDate !== null
              ? props.legalTransaction.StartEditDate
              : props.legalTransaction.NotarizationDate != null
              ? props.legalTransaction.NotarizationDate
              : null
          }
        />
      ))}
    </>
  );
};
